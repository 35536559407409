/**
 * Transaction process graph for product orders:
 *   - default-purchase
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  CONFIRM_PURCHASE: 'transition/confirm-purchase',
  // Operator can cancel the purchase before product has been marked as delivered / received
  CANCEL: 'transition/cancel',
  PROVIDER_CANCEL: 'transition/provider-cancel',
  CUSTOMER_CANCEL: 'transition/customer-cancel',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

export const states = {
  INITIAL: 'initial',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-purchase-free/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {

    [states.COMPLETED]: {
      on: {
        [transitions.CONFIRM_PURCHASE]: states.COMPLETED,
      },
    },

    [states.CANCELED]: {
      on: {
        [transitions.PROVIDER_CANCEL]: states.CANCELED,
        [transitions.CUSTOMER_CANCEL]: states.CANCELED,
      }
    },


    // [states.PURCHASED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.CONFIRM_PURCHASE,
    transitions.CANCEL,
    transitions.CUSTOMER_CANCEL,
    transitions.PROVIDER_CANCEL,
  ].includes(transition);
};


// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.CONFIRM_PURCHASE].includes(
    transition
  );
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.CONFIRM_PURCHASE,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  return false;
};


export const isCustomerReview = transition => {
  return false;
};

export const isProviderReview = transition => {
  return false
};

export const statesNeedingProviderAttention = [];
