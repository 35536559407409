import moment from 'moment';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { post } from '../../util/api';
export const ASSET_NAME = 'landing-page';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/EventsCalendarPage/SET_INITIAL_STATE';

export const QUERY_LISTINGS_REQUEST = 'app/EventsCalendarPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/EventsCalendarPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/EventsCalendarPage/QUERY_LISTINGS_ERROR';
// ================ Reducer ================ //

const initialState = {
  listings: null,
  queryListingsError: null,
  queryListingsInProgress: false,
};

export default function EventsCalendarPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        listings: null,
        queryListingsError: null,
        queryListingsInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, listings: payload, queryListingsInProgress: false };
    case QUERY_LISTINGS_ERROR:
      return { ...state, listings: null, queryListingsError: payload, queryListingsInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (gallery) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: gallery,
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //


export const queryListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return post('/api/fetch-gallery', {})
    .then(response => {
      console.log(response);

      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};
