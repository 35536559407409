import moment from "moment";
import { types as sdkTypes } from './sdkLoader';
const { LatLng, Money } = sdkTypes;


export const getRecurringEvents = (start, end, noOfDays, recurringUntilDate) => {

    const events = [];

    const startDate = moment(start);

    const endDate = moment(end);
    const eventDurationSeconds = endDate.diff(startDate, "seconds");

    const untilDate = moment(recurringUntilDate);
    let currentDate = startDate.clone().add(noOfDays, 'days');

    while (currentDate.isSameOrBefore(untilDate)) {
        const eventStartDate = currentDate.clone();
        const eventEndDate = currentDate.clone().add(eventDurationSeconds, "seconds");

        events.push({
            startDate: eventStartDate.toISOString(),
            formattedStartDate: eventStartDate.format('L LT'),
            endDate: eventEndDate.toISOString(),
            formattedEndDate: eventEndDate.format('L LT'),
        });
        currentDate.add(noOfDays, 'days');
    }

    return events;
}


export const getRecurringListing = (listing, startDate, endDate) => {

    const newListing = {};
    const startDateUnix = moment(startDate).utc().unix();

    newListing.title = listing.attributes.title;
    newListing.description = listing.attributes.description;
    newListing.availabilityPlan = listing.attributes.availabilityPlan;
    newListing.publicData = { ...listing.attributes.publicData, startDate, endDate, startDateUnix };
    newListing.privateData = listing.attributes.privateData;
    newListing.geolocation = new LatLng(listing.attributes.geolocation.lat, listing.attributes.geolocation.lng);
    newListing.price = new Money(listing.attributes.price.amount, listing.attributes.price.currency);


    return newListing;
}