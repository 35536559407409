import moment from 'moment';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';
// ================ Reducer ================ //

const initialState = {
  listings: null,
  queryListingsError: null,
  queryListingsInProgress: false,
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        listings: null,
        queryListingsError: null,
        queryListingsInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, listings: payload.listings, queryListingsInProgress: false };
    case QUERY_LISTINGS_ERROR:
      return { ...state, listings: null, queryListingsError: payload, queryListingsInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});


export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listings => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //


export const queryRecentListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const currentDate = moment().utc().unix();

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      pub_startDateUnix: `${currentDate},`,
      sort: '-pub_startDateUnix',
      page: 1,
      perPage: 6,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const imagesArray = response.data.included.filter(i => {
        return i.type === 'image';
      });

      const listingsWithImages = response.data?.data.map(l => {
        const listingImageId = l.relationships.images?.data[0]?.id?.uuid;

        if (!listingImageId) {
          return l;
        }

        const listingImage = imagesArray.find(i => i.id.uuid === listingImageId);
        if (listingImage) {
          l.images = [listingImage];
        }

        return l;
      });

      dispatch(queryListingsSuccess(listingsWithImages));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};
